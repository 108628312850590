import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  isValidArrayWithData,
  useEvents,
  useTrigerEvent
} from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Button,
  ButtonStatus,
  Container,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { IGetMyUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'

interface ButtonMakeOrderProps {
  cartData?: ICart
  isLoading?: boolean
  isLoadingByEvent?: boolean
  handleGetMyUsualsAndFavorites?: (
    page?: number
  ) => Promise<IGetMyUsualsAndFavoritesData>
}

const ButtonMakeOrder = ({
  cartData,
  isLoading = false,
  isLoadingByEvent = false,
  handleGetMyUsualsAndFavorites
}: ButtonMakeOrderProps): React.ReactElement => {
  const [loadingButton, setLoadingButton] = useState(false)
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('initial')
  const { trigger } = useTrigerEvent()
  const router = useRouter()
  const addressValidator =
    !cartData?.address || cartData?.address?.country !== 'CHL'
  const minValue =
    Number(
      typeof cartData?.minimumPurchase === 'string'
        ? parseFloat(cartData?.minimumPurchase.replace(/[$.]/g, ''))
        : cartData?.minimumPurchase
    ) ?? 0

  const subtotal =
    Number(
      typeof cartData?.minimumPurchase === 'string'
        ? parseFloat(cartData?.subtotal.replace(/[$.]/g, ''))
        : cartData?.subtotal
    ) ?? 0
  const hasNotMinValue = minValue !== 0 && Number(minValue) > Number(subtotal)

  const handleOnClick = async () => {
    if (hasNotMinValue) {
      return
    }

    // get my usuals and favorites
    setLoadingButton(true)
    const response = await handleGetMyUsualsAndFavorites()
    setLoadingButton(false)

    if (
      isValidArrayWithData(response?.favorites?.products) ||
      isValidArrayWithData(response?.myUsuals?.products)
    ) {
      trigger({ eventType: 'myUsuals', data: { show: true } })
    } else {
      router.push('/MyShipments')
    }
  }

  useEvents({
    eventType: 'addressChanged',
    callBack: ({ detail: { addressChanged } }) => {
      if (addressChanged) setButtonStatus('disabled')
    }
  })

  useEffect(() => {
    if (isLoadingByEvent) {
      setButtonStatus('loading')
    } else {
      if (hasNotMinValue || addressValidator) {
        setButtonStatus('disabled')
      } else {
        setButtonStatus('initial')
      }
    }
  }, [isLoadingByEvent, cartData])

  return (
    <Container isWrap>
      <Row justifyContent='center'>
        <Button
          actionType='button'
          borderRadius={getGlobalStyle('--border-radius-xl')}
          fontWeight={getGlobalStyle('--font-weight-medium')}
          height='40px'
          id='GA__button--goMyShipments'
          isLoading={isLoading || loadingButton}
          label='Continuar con el envío'
          onClick={handleOnClick}
          pointLoaderBigger={true}
          size='lg'
          status={buttonStatus}
          type='primary-gray'
          width='100%'
        />
      </Row>
    </Container>
  )
}

export default ButtonMakeOrder

import { useEffect } from 'react'
import { Column, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  isValidArrayWithData,
  useMobile,
  useOrderForm
} from '@smu-chile/pkg-unimarc-hooks'
import productsQuantity from 'shared/utils/productsQuantity/productsQuantity'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { getCategoryFromProducts } from 'shared/helpers'
import { ProductsContainer } from './ProductsContainer'

interface ProoductsProps {
  cartData: ICart
  isLoadingByEvent: boolean
  objetSrcImagesIconAddress: {
    imgByke: string
    imgStore: string
  }
  openCollapse: boolean
  openCollapseIssues: boolean
  setLimitOnProductAlert: (value: boolean) => void
  setOpenCollapse: React.Dispatch<React.SetStateAction<boolean>>
  setOpenCollapseIssues: React.Dispatch<React.SetStateAction<boolean>>
}

export const Products = ({
  cartData,
  isLoadingByEvent,
  objetSrcImagesIconAddress,
  openCollapse,
  openCollapseIssues,
  setLimitOnProductAlert,
  setOpenCollapse,
  setOpenCollapseIssues
}: ProoductsProps): React.ReactElement => {
  const { data: orderFormData, isLoading: isLoadingOrderForm } = useOrderForm()

  const categories = getCategoryFromProducts(cartData)
  const products = isValidArrayWithData(categories?.items)
    ? categories.items
    : null
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const saveQuantityItems = () => {
    const quantityItems = {}
    if (products) {
      products.forEach(({ description, price }) => {
        quantityItems[description?.id] = price?.unitMultiplier
      })
    }
    productsQuantity.updateProducts(quantityItems)
  }

  const handleCollapse = (open: boolean) => {
    setOpenCollapse(open)
  }

  const handleCollapseIssues = (open: boolean) => {
    setOpenCollapseIssues(open)
  }

  useEffect(() => {
    saveQuantityItems()
  }, [cartData?.products])

  useEffect(() => {
    if (isValidArrayWithData(cartData?.productsIssues)) {
      setOpenCollapse(false)
    }
  }, [])

  return (
    <>
      <BigScreen>
        <Column
          id={getBemId('productsList', 'container')}
          isWrap
          margin='0 56px 0 0'
          maxWidth='690px'
          minWidth='690px'
          tagName='section'
        >
          <ProductsContainer
            dataCart={cartData}
            handleCollapse={handleCollapse}
            handleCollapseIssues={handleCollapseIssues}
            isLoadingByEvent={isLoadingByEvent}
            isLoadingOrderForm={isLoadingOrderForm}
            isMobile={isMobile}
            objetSrcImagesIconAddress={objetSrcImagesIconAddress}
            openCollapse={openCollapse}
            openCollapseIssues={openCollapseIssues}
            orderFormData={orderFormData}
            setLimitOnProductAlert={setLimitOnProductAlert}
          />
        </Column>
      </BigScreen>

      <SmallScreen>
        <Column
          id={getBemId('productsList', 'container')}
          padding='0 16px'
          tagName='section'
        >
          {cartData && <Spacer.Horizontal customSize={76} />}
          <ProductsContainer
            dataCart={cartData}
            handleCollapse={handleCollapse}
            handleCollapseIssues={handleCollapseIssues}
            isLoadingByEvent={isLoadingByEvent}
            isLoadingOrderForm={isLoadingOrderForm}
            isMobile={isMobile}
            objetSrcImagesIconAddress={objetSrcImagesIconAddress}
            openCollapse={openCollapse}
            openCollapseIssues={openCollapseIssues}
            orderFormData={orderFormData}
            setLimitOnProductAlert={setLimitOnProductAlert}
          />
        </Column>
      </SmallScreen>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  Cookies,
  currentOrderformInfoTag,
  getCurrentDomain,
  useEvents,
  useMobile,
  useOrderForm
} from '@smu-chile/pkg-unimarc-hooks'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Button,
  ButtonStatus,
  Container,
  Row
} from '@smu-chile/pkg-unimarc-components'
import { IAvailableWindows } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMyShipments'
import { formatDateToSendOnTag } from 'components/MyShipments/ButtonGoToPay/helpers/formatDateToSendOnTag'
import style from './ButtonGoToPay.module.css'

interface ButtonGoToPayProps {
  customStyle?: boolean
  firstDayAvailable?: IAvailableWindows
  isLoading: boolean
  isLoadingOrderForm?: boolean
  scheduleSelected?: IAvailableWindows
  onClick?: () => void
}

const ButtonGoToPay = ({
  customStyle = false,
  firstDayAvailable,
  isLoading,
  isLoadingOrderForm = false,
  scheduleSelected,
  onClick
}: ButtonGoToPayProps): React.ReactElement => {
  const domain = getCurrentDomain()
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>('initial')
  const [loadingButton, setLoadingButton] = useState(isLoading)
  const eventSent = JSON.parse(Cookies.get('eventSent') ?? 'false')
  const { data } = useOrderForm()
  const { isMobile } = useMobile()
  const addressValidator =
    !data?.data?.selectedAddresses ||
    data?.data?.selectedAddresses?.country !== 'CHL'
  const site = 'Unimarc'
  const orderForm = data?.data

  const handleOnClick = async () => {
    setLoadingButton(true)
    if (!eventSent) {
      currentOrderformInfoTag({
        event: 'add_shipping_info',
        shipping_date: formatDateToSendOnTag(scheduleSelected?.startDateUtc),
        'shipping-earliest-date': formatDateToSendOnTag(
          firstDayAvailable?.startDateUtc
        ),
        shipping_tier: orderForm?.selectedAddresses?.addressType,
        site
      })
      Cookies.set('eventSent', 'true', {
        domain,
        path: '/'
      })
    }

    currentOrderformInfoTag({
      event: 'begin_checkout',
      site
    })

    onClick()
  }

  useEvents({
    eventType: 'shipmentWindowChanged',
    callBack: () => {
      setLoadingButton(false)
    }
  })

  useEffect(() => {
    if (isLoadingOrderForm) {
      setButtonStatus('loading')
    } else {
      if (addressValidator) {
        setButtonStatus('disabled')
      } else {
        setButtonStatus('initial')
      }
    }
  }, [isLoadingOrderForm, data?.data])

  useEffect(() => {
    setLoadingButton(isLoading)
  }, [isLoading])

  return (
    <Container isWrap>
      <Row justifyContent='center'>
        <Button
          actionType='button'
          borderRadius={getGlobalStyle('--border-radius-xl')}
          customClassName={classNames({
            [style['button__loading']]: customStyle
          })}
          fontWeight={getGlobalStyle('--font-weight-medium')}
          height={isMobile ? '40px' : '44px'}
          id='GA__button--finishOrder'
          isLoading={loadingButton}
          label='Continuar'
          onClick={handleOnClick}
          pointLoaderBigger={true}
          size={isMobile ? 'lg' : 'xl'}
          status={buttonStatus}
          type='primary-gray'
          width='100%'
        />
      </Row>
    </Container>
  )
}

export default ButtonGoToPay

import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import styles from './HeadDetail.module.css'

interface HeadDetailProps {
  headTitle: string
  openCollapse?: boolean
  showIconCollapse: boolean
  totalQuantity: number
  onClickCollapse?: () => void
}

const HeadDetail = ({
  headTitle,
  openCollapse,
  onClickCollapse,
  showIconCollapse,
  totalQuantity
}: HeadDetailProps) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const { isMobile } = useMobile()

  const padding = isMobile && !openCollapse ? '0 0 15px 0' : '0 0 8px 0'
  const paddingContainer = openCollapse ? '15px 16px 20px' : '15px 16px 0'

  useEffect(() => {
    setIsFirstLoad(false)
  }, [])

  return (
    <Container
      onClick={() => {
        return onClickCollapse()
      }}
      style={{
        height: 'auto',
        padding: isMobile ? paddingContainer : '0',
        zIndex: isMobile ? '9' : '0'
      }}
    >
      <Row
        clickable='pointer'
        customClassName={openCollapse ? '' : styles.borderBottom}
        padding={isMobile ? padding : '20px 16px 10px'}
        tagName='section'
      >
        <Column justifyContent='between'>
          <Row
            alignItems='center'
            clickable='pointer'
            id={getBemId('titleShelves', 'container')}
          >
            <Column maxWidth='max-content'>
              <Text
                clickable='pointer'
                color='black'
                fontSize={isMobile ? 'lg' : 'xl'}
                fontWeight='medium'
              >
                {headTitle}
              </Text>
            </Column>
            <Spacer.Vertical size={4} />
            <Column maxWidth='max-content'>
              <Text
                fontSize={isMobile ? 'sm' : 'lg'}
                fontWeight='regular'
              >
                ({totalQuantity})
              </Text>
            </Column>
          </Row>
        </Column>
        <Column
          id={getBemId('titleShelvesIcon', 'container')}
          maxWidth='max-content'
        >
          {!isFirstLoad && showIconCollapse && (
            <Container
              customClassName={classNames({
                [styles.transitionToClose]: openCollapse,
                [styles.transitionToOpen]: !openCollapse
              })}
            >
              <Icon
                clickable='pointer'
                color={getGlobalStyle('--color-base-black')}
                customSize={24}
                name='DropDownOpenV2'
              />
            </Container>
          )}
        </Column>
      </Row>
    </Container>
  )
}

export default HeadDetail

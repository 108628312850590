import dynamic from 'next/dynamic'
import { Row, Container, Spacer } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Products } from './Products'
import { Totalizers } from './Totalizers'
import { useState } from 'react'
import { BodyLoader } from '../BodyLoader'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { IGetMyUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import { OfferYouCarouselProps } from '../OfferYou'
import {
  useMobile,
  useSimulationsForPayments
} from '@smu-chile/pkg-unimarc-hooks'
import { UNIPAY_ID } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { useSavingsData } from 'shared/hooks/useSavingsData'

const DynamicOfferYou = dynamic<OfferYouCarouselProps>(() => {
  return import('../OfferYou').then((module) => {
    return module.OfferYouCarousel
  })
})

export interface ProductsTotalizersProps {
  cartData: ICart
  createListButton: boolean
  isFetchingCart: boolean
  isLoadingByEvent: boolean
  isLoadingCart: boolean
  isLoadingOrderForm: boolean
  notificationAddToList: boolean
  objetSrcImagesIconAddress: {
    imgByke: string
    imgStore: string
  }
  openCollapse: boolean
  openCollapseIssues: boolean
  openMyUsuals: boolean
  selectedListName: string
  handleGetMyUsualsAndFavorites: (
    page?: number
  ) => Promise<IGetMyUsualsAndFavoritesData>
  handleOpenMyListsModal: () => void
  handleOnClosed: () => void
  setNotificationAddToList: (type: boolean) => void
  setOpenCollapse: React.Dispatch<React.SetStateAction<boolean>>
  setOpenCollapseIssues: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProductsTotalizers = ({
  cartData,
  createListButton,
  isFetchingCart,
  isLoadingByEvent,
  isLoadingCart,
  isLoadingOrderForm,
  notificationAddToList,
  objetSrcImagesIconAddress,
  openCollapse,
  openCollapseIssues,
  openMyUsuals,
  selectedListName,
  handleGetMyUsualsAndFavorites,
  handleOpenMyListsModal,
  handleOnClosed,
  setNotificationAddToList,
  setOpenCollapse,
  setOpenCollapseIssues
}: ProductsTotalizersProps): React.ReactElement => {
  const [limitOnProductAlert, setLimitOnProductAlert] = useState(false)
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const {
    data: simulationData,
    refetch: refetchSimulation,
    isFetching: isFetchingSimulation
  } = useSimulationsForPayments({
    payments: [UNIPAY_ID]
  })

  // membership savings
  const {
    isLoadingCF,
    isLoadingMembershipSavings,
    itemFromCF,
    refetchSavings,
    savings
  } = useSavingsData()

  const toastMembershipProps = {
    isLoading: isLoadingMembershipSavings || isLoadingCF,
    item: itemFromCF,
    savings
  }

  if (!cartData || isLoadingCart || isLoadingOrderForm) {
    return <BodyLoader />
  }

  return (
    <Container isWrap>
      <Row tagName='section'>
        <Products
          cartData={cartData}
          isLoadingByEvent={isLoadingByEvent}
          objetSrcImagesIconAddress={objetSrcImagesIconAddress}
          openCollapse={openCollapse}
          openCollapseIssues={openCollapseIssues}
          setLimitOnProductAlert={setLimitOnProductAlert}
          setOpenCollapse={setOpenCollapse}
          setOpenCollapseIssues={setOpenCollapseIssues}
        />
        <Totalizers
          cartData={cartData}
          createListButton={createListButton}
          handleGetMyUsualsAndFavorites={handleGetMyUsualsAndFavorites}
          handleOnClosed={handleOnClosed}
          handleOpenMyListsModal={handleOpenMyListsModal}
          isFetchingCart={isFetchingCart}
          isFetchingSimulation={isFetchingSimulation}
          isLoadingByEvent={isLoadingByEvent}
          isLoadingCart={isLoadingCart}
          limitOnProductAlert={limitOnProductAlert}
          notificationAddToList={notificationAddToList}
          openMyUsuals={openMyUsuals}
          refetchSavings={refetchSavings}
          refetchSimulation={refetchSimulation}
          selectedListName={selectedListName}
          setLimitOnProductAlert={setLimitOnProductAlert}
          setNotificationAddToList={setNotificationAddToList}
          simulationData={simulationData}
          toastMembership={{ ...toastMembershipProps }}
        />
      </Row>
      <BigScreen>
        <Spacer.Horizontal size={12} />
      </BigScreen>
      <Row
        padding={isMobile ? '0px 0px 0px 7.5px' : '0'}
        tagName='section'
      >
        <DynamicOfferYou cartData={cartData} />
      </Row>
      <SmallScreen>
        <>
          {!toastMembershipProps?.isLoading &&
          toastMembershipProps?.savings &&
          toastMembershipProps?.item?.showbuttom ? (
            <Spacer.Horizontal customSize={220} />
          ) : (
            <Spacer.Horizontal customSize={148} />
          )}
        </>
      </SmallScreen>
    </Container>
  )
}

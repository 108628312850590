/* eslint-disable @typescript-eslint/no-explicit-any */

import { isValidArrayWithData, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import ButtonMakeOrder from './ButtonMakeOrder'
import { Row, Spacer, Text } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { TotalUnipay } from '../TotalUnipay'
import { ISimulationResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ISimulation'
import { IGetMyUsualsAndFavoritesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'

interface TotalizerButtonsProps {
  cartData?: ICart
  minValueToBuy?: string | number
  showMyUsuals?: boolean
  simulationData?: ISimulationResponse
  isFetchingSimulation?: boolean
  isLoadingByEvent?: boolean
  isLoadingQueryOrderForm?: boolean
  subtotal?: string
  isLoading?: boolean
  tooltip?: boolean
  handleGetMyUsualsAndFavorites?: (
    page?: number
  ) => Promise<IGetMyUsualsAndFavoritesData>
}

export const TotalizerButtons = ({
  cartData,
  minValueToBuy,
  showMyUsuals = false,
  simulationData,
  subtotal,
  tooltip = false,
  isFetchingSimulation = false,
  isLoadingByEvent,
  isLoadingQueryOrderForm = false,
  isLoading = false,
  handleGetMyUsualsAndFavorites
}: TotalizerButtonsProps): React.ReactElement => {
  const disableSubTotals = isLoading
    ? getGlobalStyle('--color-primary-disabled')
    : getGlobalStyle('--color-base-black')
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  return (
    <>
      {!!subtotal && !showMyUsuals && (
        <>
          <Row
            id={getBemId('subtotal', 'container')}
            justifyContent='between'
          >
            <Text
              customColor={disableSubTotals}
              fontSize='lg'
              fontWeight='semibold'
              id={getBemId('subtotal', 'text')}
            >
              Subtotal
            </Text>
            <Text
              customColor={disableSubTotals}
              fontSize='lg'
              fontWeight='semibold'
              id={getBemId('subtotalPrice', 'text')}
            >
              {subtotal}
            </Text>
          </Row>
          {simulationData &&
            isValidArrayWithData(simulationData?.simulations) &&
            !isLoadingQueryOrderForm &&
            !isLoading &&
            !isFetchingSimulation && (
              <TotalUnipay simulationData={simulationData} />
            )}
          {tooltip && (
            <>
              <Spacer.Horizontal size={16} />
              <Row
                alignItems='center'
                backgroundColor='#FFE5E5'
                borderRadius='8px'
                id={getBemId('minimumPrice', 'container')}
                maxWidth='100%'
                padding='8px 10px'
                position='relative'
              >
                <Text
                  color='red'
                  fontSize='sm'
                  fontWeight='medium'
                  id={getBemId('minimumPrice', 'text')}
                >
                  {`🛒  Monto mínimo de compra`}
                </Text>
                <Spacer.Vertical size={4} />
                <Text
                  color='red'
                  fontSize='sm'
                  fontWeight='semibold'
                  id={getBemId('minimumPrice', 'price')}
                >
                  ${minValueToBuy}
                </Text>
              </Row>
            </>
          )}
          <Spacer.Horizontal size={isMobile ? 16 : 18} />
        </>
      )}
      <Row justifyContent='center'>
        <ButtonMakeOrder
          cartData={cartData}
          handleGetMyUsualsAndFavorites={handleGetMyUsualsAndFavorites}
          isLoading={isLoading}
          isLoadingByEvent={isLoadingByEvent}
        />
      </Row>
    </>
  )
}

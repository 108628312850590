import { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { MemoShelf } from '@smu-chile/pkg-unimarc-components'
import { IProductsList } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMyUsualsAndFavorites'
import { DEFAULT_CART_LIMIT } from 'components/Body/ProductsTotalizers/Products/Shelves/Shelf/QuantityWrapper/QuantityWrapper'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { useEvents } from '@smu-chile/pkg-unimarc-hooks'
import { statusPromotionsV2 } from '@smu-chile/pkg-unimarc-components/helpers/promotionsOfShelvesV2'
import { getDataPromotion } from 'shared/helpers/promotionsOfShelves'

interface ShelfContentProps {
  dataPromotions: unknown[]
  product: IProductsList
  indexItem?: number
  isLoadingOrderForm: boolean
  isMobile?: boolean
  handleAdd: (props?: unknown) => void
  handleOnClick?: (props?: unknown) => void
  handleRemove: (props?: unknown) => void
  handleShowToast: (value: boolean) => void
}

export const ShelfContent = ({
  dataPromotions,
  product,
  indexItem,
  isLoadingOrderForm,
  isMobile,
  handleAdd,
  handleOnClick,
  handleRemove,
  handleShowToast
}: ShelfContentProps): React.ReactElement => {
  const { item, price, priceDetail } = product
  const [reachedLimit, setReachedLimit] = useState(false)
  const [quantity, setQuantity] = useState(0)

  const wasLoadingOrderForm = useRef(false)

  const format =
    item?.measurementUnit?.toLowerCase() === 'kg'
      ? item?.netContentLevelSmall
      : item?.netContent

  const maxQuantity = item?.cartLimit > 0 ? item?.cartLimit : DEFAULT_CART_LIMIT

  const typePromotion = {
    '': 0,
    price: 1,
    percentage: 2,
    mxn: 3,
    mx$: 4,
    'm%n': 5
  }

  const {
    isPromotion,
    isPaymentsMethods,
    price: priceProduct,
    pricePromotion,
    ppum,
    offer,
    pricePaymentsMethods
  } = statusPromotionsV2(product)

  const promotion = getDataPromotion(
    dataPromotions,
    priceDetail?.promotionalTagCode
  )

  const handleAddQuantity = ({ itemId }) => {
    setQuantity(quantity + 1)
    handleAdd({ itemId })
    if (item?.cartLimit > 0 && quantity + 1 >= item?.cartLimit) {
      setReachedLimit(true)
    }
  }

  const handleRemoveQuantity = ({ itemId }) => {
    setQuantity(quantity - 1)
    handleRemove({ itemId })
  }

  const handleOnClickQuantity = ({ itemId }) => {
    setQuantity(quantity + 1)
    handleOnClick({ itemId })
  }

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail }) => {
      if (detail?.loading && detail?.items?.length) {
        const countItems = detail?.items.find((element) => {
          return element?.id == item?.itemId
        })
        if (countItems) {
          setQuantity(countItems?.quantity)
        }
      }
    }
  })

  useEffect(() => {
    if (!isLoadingOrderForm && wasLoadingOrderForm.current && reachedLimit) {
      handleShowToast(true)
      setReachedLimit(false)
    }

    wasLoadingOrderForm.current = isLoadingOrderForm
  }, [isLoadingOrderForm, reachedLimit])

  return (
    <MemoShelf
      {...{ ...item, format }}
      altText={item?.name}
      containerWidth='25%'
      format={format}
      handleOnClick={handleOnClickQuantity}
      id={getBemId('myUsualsAndFavoritesBody', 'shelf', indexItem.toString())}
      img={item?.images?.[0]}
      inOffer={price?.inOffer}
      isMobile={isMobile}
      isMporcentageNPromo={priceDetail?.promotionType === 'm%n'}
      isMxNPromotion={priceDetail?.promotionType === 'mxn'}
      isPaymentsMethods={isPaymentsMethods}
      isPromotion={isPromotion}
      itemRequired={priceDetail?.itemsRequiredForPromotion}
      likeListButtons={false}
      listPrice={priceDetail?.listPrice}
      maxQuantity={item?.cartLimit > 0 ? item?.cartLimit : 0}
      membershipData={priceDetail?.membership}
      nextImage={Image}
      offer={offer}
      orientation='vertical'
      ppum={`(${ppum})`}
      ppumListPrice={priceDetail?.ppumListPrice}
      price={priceProduct}
      priceDetail={priceDetail}
      pricePaymentsMethods={pricePaymentsMethods}
      pricePromotion={pricePromotion}
      promotion={promotion}
      quantity={quantity}
      quantityButtonProps={{
        quantity,
        maxQuantity,
        handleAdd: handleAddQuantity,
        handleRemove: handleRemoveQuantity
      }}
      quantityButtonStyles={{
        fontSize: 'md',
        maxHeight: '30px',
        maxWidth: '164px',
        sizeCircle: 22
      }}
      saving={priceDetail?.promotionMessage}
      title={item?.name}
      typePromotion={typePromotion[priceDetail?.promotionType]}
      version={2}
    />
  )
}

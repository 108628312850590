import {
  useUserWithMembership,
  useContentful,
  IEntriesProps,
  ItemFields,
  useMembershipSavings,
  QueryObserverResult
} from '@smu-chile/pkg-unimarc-hooks'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { IGetMembershipSavingsResponseSchema } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMembershipSavings'
import { MEMBERSHIP_BOX } from 'shared/utils/constants'

export interface IUseSavingsData {
  isLoadingCF: boolean
  isLoadingMembershipSavings: boolean
  itemFromCF: ItemFields
  savings: IGetMembershipSavingsResponseSchema
  refetchSavings: (options?: {
    throwOnError?: boolean
  }) => Promise<QueryObserverResult<IGetMembershipSavingsResponseSchema>>
}

/**
 * Custom hook to fetch membership data and associated content from Contentful.
 *
 * Fetches membership status data for the current user and, based on the membership
 * status, retrieves specific configuration content from Contentful.
 *
 * @returns {IUseSavingsData} Returns an object containing:
 * - `itemFromCF` {Object} - The first item from Contentful content for the user's membership, or `undefined` if not found.
 * - `isLoadingMembership` {boolean} - Loading state for membership data.
 * - `isLoadingCF` {boolean} - Loading state for Contentful data.
 *
 * @example
 * import { useMembershipData } from './path/to/hooks'
 */
export const useSavingsData = (): IUseSavingsData => {
  const {
    data: savings,
    isLoading: isLoadingMembershipSavings,
    refetch: refetchSavings
  } = useMembershipSavings()

  const { data: dataMembership } = useUserWithMembership({
    staleTime: FIVE_MINUTES_OUTDATED_INFO
  })

  const membershipStatus = dataMembership?.membership ?? 'default'

  const { data: CFData, isLoading: isLoadingCF } = useContentful({
    id_contentful: 'contentful-configuration',
    options: {
      'sys.id': MEMBERSHIP_BOX[membershipStatus]
    },
    reactQuery: {
      queryKey: 'membershipBox',
      refetchInterval: 60000
    }
  })

  const data = CFData as IEntriesProps

  return {
    isLoadingCF,
    isLoadingMembershipSavings,
    itemFromCF: data?.items[0]?.fields,
    refetchSavings,
    savings
  }
}

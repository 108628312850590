import { NotificationBubble, Tooltip } from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { ItemFields } from '@smu-chile/pkg-unimarc-hooks'
import { ToastBody } from './ToastBody'

export interface MembershipToastProps {
  item: ItemFields
  savings: string
  showingUnipay?: boolean
}

export const MembershipToast = ({
  item,
  savings,
  showingUnipay
}: MembershipToastProps): React.ReactElement => {
  return (
    <>
      <BigScreen>
        <Tooltip
          alignAllItems='center'
          background={getGlobalStyle('--color-secondary-blue-bg')}
          borderRadius={getGlobalStyle('--border-radius-md')}
          boxShadow='none'
          closeColor={getGlobalStyle('--color-neutral-black')}
          closeIcon={false}
          display='flex'
          height='60px'
          maxWidth='375px'
          padding='12px 16px'
          position='sticky'
          positionContainer='sticky'
          textComponent={
            <ToastBody
              item={item}
              savings={savings}
            />
          }
          type='bottom'
          width='375px'
        />
      </BigScreen>
      <SmallScreen>
        <NotificationBubble
          background='--color-secondary-blue-bg'
          customPadding='12px 16px'
          customTop={showingUnipay ? '-98px' : '-68px'}
          height='85px'
          isSticky={true}
          minWidth='100%'
        >
          <ToastBody
            item={item}
            savings={savings}
          />
        </NotificationBubble>
      </SmallScreen>
    </>
  )
}

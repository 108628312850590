import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { Column, Picture } from '@smu-chile/pkg-unimarc-components'
import {
  isValidArrayWithData,
  useFeatureFlag
} from '@smu-chile/pkg-unimarc-hooks'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { AddressSelected } from 'components/AddressSelected'
import { getCategoryFromProducts } from 'shared/helpers'
import { ProductAccordion } from '../ProductAccordion'
import { FEATURES_FLAGS_IDENTIFIERS } from 'shared/utils/constants'

interface ProductsContainerProps {
  dataCart: ICart
  isLoadingByEvent: boolean
  isLoadingOrderForm: boolean
  isMobile: boolean
  openCollapse: boolean
  openCollapseIssues: boolean
  orderFormData: ResponseOrderFormProps
  objetSrcImagesIconAddress: {
    imgByke: string
    imgStore: string
  }
  handleCollapse: (open: boolean) => void
  handleCollapseIssues: (open: boolean) => void
  setLimitOnProductAlert: (value: boolean) => void
}

export const ProductsContainer = ({
  dataCart,
  isLoadingByEvent,
  isLoadingOrderForm,
  isMobile,
  openCollapse,
  openCollapseIssues,
  orderFormData,
  objetSrcImagesIconAddress,
  handleCollapse,
  handleCollapseIssues,
  setLimitOnProductAlert
}: ProductsContainerProps): React.ReactElement => {
  const hideWithTestFlag = useFeatureFlag(FEATURES_FLAGS_IDENTIFIERS.testFlag)
  const products =
    isValidArrayWithData(dataCart?.products) &&
    getCategoryFromProducts(dataCart)

  const allProducts = dataCart?.products?.find(({ categoryName }) => {
    return categoryName === 'Todos'
  }) ?? { totals: 0 }
  const totalQuantity = allProducts?.totals

  const totalProductsIssues = dataCart?.productsIssues.reduce(
    (accumulator: number, current: { orderItem: { leftQuantity: number } }) => {
      return accumulator + (current.orderItem?.leftQuantity ?? 0)
    },
    0
  )

  const sizeImage = isMobile ? '50px' : '80px'

  return (
    <>
      {!isLoadingOrderForm && orderFormData?.data?.selectedAddresses && (
        <>
          {!hideWithTestFlag ? (
            <AddressSelected
              isLoading={isLoadingByEvent}
              isMobile={isMobile}
              objetSrcImagesIconAddress={objetSrcImagesIconAddress}
              selectedAddress={orderFormData?.data?.selectedAddresses}
            />
          ) : null}
        </>
      )}
      <Column tagName='section'>
        {isValidArrayWithData(products?.items) && (
          <ProductAccordion
            dataCart={dataCart}
            handleCollapse={handleCollapse}
            headTitle='Mis productos 🥑'
            isLoadingByEvent={isLoadingByEvent}
            itemsAmount={products?.items?.length}
            openCollapse={openCollapse}
            setLimitOnProductAlert={setLimitOnProductAlert}
            totalQuantity={totalQuantity}
          >
            {products?.items.map((item, index) => {
              return (
                <Picture
                  alt={item?.description?.name}
                  height={sizeImage}
                  id={getBemId('product', 'image', index.toString())}
                  key={item?.description?.id}
                  objectFit='contain'
                  src={item?.description?.image}
                  width={sizeImage}
                />
              )
            })}
          </ProductAccordion>
        )}

        {isValidArrayWithData(dataCart?.productsIssues) && (
          <ProductAccordion
            dataCart={dataCart}
            handleCollapse={handleCollapseIssues}
            headTitle='Productos no disponibles'
            isLoadingByEvent={isLoadingByEvent}
            isProductsWithIssues={true}
            itemsAmount={dataCart?.productsIssues?.length}
            openCollapse={openCollapseIssues}
            setLimitOnProductAlert={setLimitOnProductAlert}
            totalQuantity={totalProductsIssues}
          >
            {dataCart?.productsIssues?.map((product, index) => {
              return (
                <Picture
                  alt={product?.orderItem?.name}
                  height={sizeImage}
                  id={getBemId('productIssue', 'image', index.toString())}
                  key={product?.orderItem?.id}
                  objectFit='contain'
                  src={product?.orderItem?.image}
                  style={{
                    opacity: 0.5
                  }}
                  width={sizeImage}
                />
              )
            })}
          </ProductAccordion>
        )}
      </Column>
    </>
  )
}

import { useEffect } from 'react'
import { TooltipLimitCart } from './TooltipLimitCart'
import { ToastMessage } from './ToastMessage'
import { TooltipAddOrderToList } from './TooltipAddOrderToList'
import { TooltipAlcohol } from './TooltipAlcohol'
import { TooltipError } from './TooltipError'
import { IGetMembershipSavingsResponseSchema } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetMembershipSavings'
import { ItemFields } from '@smu-chile/pkg-unimarc-hooks'
import { MembershipToast } from './MembershipToast'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers'

interface TooltipWrapperProps {
  alcoholLaw: boolean
  codeError?: string
  createListButton: boolean
  errorMessage: string
  isMobile: boolean
  limitOnProductAlert: boolean
  notificationAddToList: boolean
  showTooltipError: boolean
  selectedListName: string
  toastMembership?: {
    item: ItemFields
    isLoading: boolean
    savings: IGetMembershipSavingsResponseSchema
  }
  toastMessage: {
    message: string
    show: boolean
  }
  closeAlcoholLaw: () => void
  closeLimitOnProductAlert: () => void
  closeTooltipError: () => void
  handleOnClosed: () => void
  setLimitOnProductAlert: (value: boolean) => void
  setNotificationAddToList: (type: boolean) => void
}

export const TooltipWrapper = ({
  alcoholLaw,
  codeError,
  createListButton,
  errorMessage,
  isMobile,
  limitOnProductAlert,
  notificationAddToList,
  showTooltipError,
  selectedListName,
  toastMembership,
  toastMessage,
  closeAlcoholLaw,
  closeLimitOnProductAlert,
  closeTooltipError,
  handleOnClosed,
  setLimitOnProductAlert,
  setNotificationAddToList
}: TooltipWrapperProps) => {
  useEffect(() => {
    setTimeout(() => {
      setLimitOnProductAlert(false)
    }, 4000)
  }, [])

  return (
    <>
      {alcoholLaw && <TooltipAlcohol onClose={closeAlcoholLaw} />}
      {toastMessage.show && (
        <ToastMessage
          message={toastMessage.message}
          onClose={closeAlcoholLaw}
        />
      )}
      {notificationAddToList && (
        <TooltipAddOrderToList
          createListButton={createListButton}
          isMobile={isMobile}
          onClose={handleOnClosed}
          selectedListName={selectedListName}
          setNotificationAddToList={setNotificationAddToList}
        />
      )}
      {limitOnProductAlert && (
        <TooltipLimitCart onClose={closeLimitOnProductAlert} />
      )}
      {showTooltipError && (
        <TooltipError
          codeError={codeError}
          errorMessage={errorMessage}
          onClose={closeTooltipError}
        />
      )}
      {!toastMembership?.isLoading &&
        toastMembership?.savings &&
        toastMembership?.item?.showbuttom && (
          <BigScreen>
            <MembershipToast
              item={toastMembership?.item}
              savings={toastMembership?.savings?.savings}
            />
          </BigScreen>
        )}
    </>
  )
}
